function hourNumericMinuteNumeric(dateString) {
    // 03:21
    return new Intl.DateTimeFormat('da-DK', { hour: 'numeric', minute: 'numeric' }).format(new Date(dateString));
}

function dayLongDayNumericMonthLong(dateString, lang = 'da') {
    // Tirsdag 12. Juli
    return new Intl.DateTimeFormat(lang, { weekday: 'long', month: 'long', day: 'numeric' }).format(new Date(dateString));
}

export { hourNumericMinuteNumeric, dayLongDayNumericMonthLong }