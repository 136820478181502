import {QueryAbstract} from './query-abstract';
import {ORDER_DIRECTIONS} from '../../../configuration/order-directions';

const ORDER_DIRECTIONS_VALUES = Object.values(ORDER_DIRECTIONS);

class QueryOrder extends QueryAbstract {
  reset() {
    this._orderBy = null;
    this._order = null;
  }

  serialize() {
    return {
      order_by: this._orderBy,
      order: this._order,
    }
  }

  unserialize(filter) {
    const { order_by, order } = filter;

    this._orderBy = order_by;
    this._order = order;
  }

  set order(value) {
    if (typeof value !== 'string') throw this._getWrongTypeError(value);
    if (!ORDER_DIRECTIONS_VALUES.includes(value)) throw RangeError(`Only ${ORDER_DIRECTIONS_VALUES.join(', ')} allowed for order.`);

    this._order = value;
  }

  get order() {
    return this._order;
  }

  set orderBy(value) {
    if (typeof value !== 'string') throw this._getWrongTypeError(value);

    this._orderBy = value;
  }

  get orderBy() {
    return this._orderBy;
  }
}

export { QueryOrder }