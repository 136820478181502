<template>
  <div class="loading-animation-wrapper">
    <div class="loading-animation" ref="animationContainer" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import lottie from 'lottie-web';
import animationData from './loading.json';

export default defineComponent({
  name: "LoadingAnimation",
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.animationContainer, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }
});
</script>

<style lang="scss" scoped>
.loading-animation-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.loading-animation {
  width: 120px;
  height: auto;
}
</style>