import { createRouter, createWebHistory } from "@ionic/vue-router";
import ChatList from "../views/conversation-list/pages/chat-list.vue";
import ChatDetail from "../views/conversation-detail/pages/chat-detail.vue";
import LoginPage from "../views/LoginPage.vue";
import LogoutPage from "../views/LogoutPage.vue";
import ProfilePage from "../views/ProfilePage.vue";
import BulkDetail from "@/views/bulk-messaging/pages/bulk-detail.vue";
import ResumeHandler from "../views/ResumeHandler.vue";
import { store } from "../store";

const routes = [
  {
    path: "/",
    redirect: "/chat",
    meta: {
      public: true,
    },
  },
  {
    path: "/resume",
    name: "Resume",
    component: ResumeHandler,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
  },
  {
    path: "/chat",
    name: "ChatList",
    component: ChatList,
  },
  {
    path: "/chat/:id",
    name: "ChatDetail",
    component: ChatDetail,
  },
  {
    path: "/bulk",
    name: "Bulk",
    component: BulkDetail,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      public: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    meta: {
      public: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isPublicRoute = to.matched.some((record) => record.meta.public);

  if (isPublicRoute) {
    await store.dispatch("auth/logout");
  }

  if (store.getters["auth/isAuthenticated"] && !store.getters["users/me"]) {
    await store.dispatch("users/get_me");
    const me = store.getters["users/me"];
    store.dispatch("split/setup", me.id);
  }

  // strip a logouts "redirect" if the requested route is login,
  // so that we don't logout on login and have to login twice
  if (
    (to.path === "/login" &&
      to.query.redirect &&
      to.query.redirect.startsWith("/logout")) ||
    (!isPublicRoute && !store.getters["auth/isAuthenticated"])
  ) {
    return next({
      path: "/login",
      query: {
        ...to.query,
        redirect: undefined,
      },
    });
  }

  return next();
});

export { router };
