const requiredEnvironmentVariables = [
    'VUE_APP_ATTACHMENT_URL',
    'VUE_APP_API_URL',
    'VUE_APP_CUSTOMER_APP_URL',
    'VUE_APP_PARTNER_APP_URL',
    'VUE_APP_HUB_URL',
    'VUE_APP_BUGSNAG_API_KEY',
    'VUE_APP_PUSHER_CLUSTER',
    'VUE_APP_PUSHER_KEY',
    'VUE_APP_SPLITIO_API_KEY',
];

const providedVueKeys = Object.keys(process.env).filter(key => key.startsWith('VUE_APP_'));

// more required env variables than provided keys
if (!(requiredEnvironmentVariables.every(k => providedVueKeys.includes(k)))) {
    console.warn(`The environment does not provide all the required VUE_APP* keys.
    Could it be that the environment variables are missing a required environment variable?
    Make sure it also gets added on CLOUDFLARE so the prod preview works and preview builds work.`);
}

// more provided keys than required env variables
if (!(providedVueKeys.every(k => requiredEnvironmentVariables.includes(k)))) {
    console.warn(`The environment provides more VUE_APP* keys than we require.
    Could it be that the required environment variables are missing an environment variable?
    Make sure it also gets added on CLOUDFLARE so the prod preview works and preview builds work.`);
}

requiredEnvironmentVariables.forEach(env => {
    if (!process.env[env]) throw Error(`Missing required environment variable ${env}.`);
    console.info(`${env}=${process.env[env]}`);
})