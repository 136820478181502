<template>
  <div data-cy="view-logout"></div>
</template>

<script>
export default {
  name: 'LogoutPage',
  async mounted() {
    try {
      await this.$store.dispatch('auth/logout');
    } catch (e) {
      // If we're already logged out with the backend but still have a token,
      // e.g. when the token expired, we get a 401 but don't want the interceptor
      // that we have in place to take care of it
      if (!e.message.includes('401')) throw e;
    }

    // To not reset the initialization process and deal with all this,
    // we decided to just refresh the application on logout, so all the
    // initialization is done again on login
    // @FIXES https://officeguru.atlassian.net/browse/OG-1919
    window.location.href = `/login${window.location.search}`;
  },
};
</script>
