<template>
  <div
    class="personalized-table-header__cell"
    :class="headerCellClasses"
    @click="onCellClick"
  >
    <span class="label">{{ cellContent.label }}</span>
    <span
      v-if="canOrder"
      class="order"
      :class="orderClasses"
    >
      <Icon
        class="caret caret-up"
        name="caret-down"
        :size="14"
      />
      <Icon
        class="caret caret-down"
        name="caret-down"
        :size="14"
      />
    </span>
  </div>
</template>

<script>
import Icon from '../Icon.vue';

const DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export default {
  name: 'PersonalizedTableHeaderCell',
  components: {
    Icon,
  },
  props: {
    cellContent: {
      type: Object,
      required: true,
    },
    orderMap: {
      validator(val) {
        return val !== null && typeof val === 'object';
      },
      default: () => ({}),
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['ordering'],
  computed: {
    canOrder() {
      if (!(this.cellContent && this.cellContent.value)) return false;

      return ![undefined, null].includes(this.cellContent.value);
    },
    orderClasses() {
      if (!this.isActiveOrderBy) return null;

      return {
        ascending: this.orderMap.order === DIRECTIONS.ASC,
        descending: this.orderMap.order === DIRECTIONS.DESC,
      };
    },
    headerCellClasses() {
      return { 'can-order': this.canOrder };
    },
    nextOrderDirection() {
      // if it's currently active, toggle directions
      if (this.isActiveOrderBy) {
        return this.orderMap.order === DIRECTIONS.ASC ? DIRECTIONS.DESC : DIRECTIONS.ASC;
      }

      // for a fresh one, always start ASCENDING
      return DIRECTIONS.ASC;
    },
    isActiveOrderBy() {
      return this.cellContent.value === this.orderMap.orderBy;
    },
  },
  methods: {
    onCellClick() {
      if (!this.canOrder || this.showSkeleton) return;

      this.$emit('ordering', {
        order: this.nextOrderDirection,
        orderBy: this.cellContent.value,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

$caretInactive: $color-grey-tinted-300;
$caretActive: $color-grey-tinted-500;

.personalized-table-header__cell {
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;
  user-select: none;
  text-align: left;
  padding: 16px 0 16px 24px;

  &.can-order {
    cursor: pointer;
  }

  &:last-child {
    padding-right: 24px;
  }

  &:hover {

    .ascending {

      .caret-down {
        color: $caretActive;
      }
    }

    .descending,
    :not(.descending) + :not(.ascending) {

      .caret-up {
        color: $caretActive;
      }
    }
  }
}

.order {
  color: $caretInactive;
  margin-left: 2px;
  display: flex;
  flex-direction: column;

  &.ascending {

    .caret-up {
      color: $caretActive;
    }
  }

  &.descending {

    .caret-down {
      color: $caretActive;
    }
  }
}

.caret {
  transition: color .3s ease-in-out;
}

.caret-up {
  margin-top: 2px;
  transform: rotate(180deg);
  margin-bottom: -8px;
}

.label {
  @include overflow-ellipsis;
}
</style>
