<template>
  <div
      class="conversation-handled-banner"
      data-cy="conversation-handled-banner"
  >
    <Icon
        name="checkmark_bubble_bold"
        class="conversation-handled-banner__icon"
        :size="18"
        color="#08856A"
    />
    <p class="handled-by-text" @click="toggleHandledByTooltip($event)">
      {{ $t('shared.conversations.handled_by_name', { name: conversation.handled?.user.name }) }}
    </p>
    <p class="handled-by-time">
      {{ handledTime }}
    </p>
    <InfoTag
        class="handled-by-infotag"
        small
        @click="toggleHiddenFromCustomerTooltip($event)"
    >
      <Icon
          name="eye-crossed"
          :size="12"
      />
    </InfoTag>
    <OgTooltip :message="$t('ogchat.this_can_not_be_seen_by_customer')"
               @interface="hiddenFromCustomerTooltip = $event"
               ref="hiddenFromCustomerTooltip"
               data-cy="hidden-from-customer-tooltip"
    />
    <OgTooltip :message="conversation.handled?.user.name"
               @interface="handledByTooltip = $event"
               ref="handleByTooltip"
               data-cy="handled-by-tooltip"
    />
  </div>
</template>

<script>

import { format } from 'date-fns';
import InfoTag from '@officeguru/components-vue3/src/components/InfoTag.vue';
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import OgTooltip from "@/components/OgTooltip.vue";

export default {
  name: 'ConversationHandledBanner',
  components: { Icon, InfoTag, OgTooltip },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenFromCustomerTooltip: null,
      handledByTooltip: null,
    }
  },
  computed: {
    handledTime() {
      return format(new Date(this.conversation.handled.handled_at), 'HH:mm');
    },
  },
  methods: {
    toggleHiddenFromCustomerTooltip(event) {
      this.hiddenFromCustomerTooltip.toggle({x: event.clientX, y: event.clientY});
    },
    toggleHandledByTooltip(event) {
      this.handledByTooltip.toggle({x: event.clientX, y: event.clientY});
    },
  }
};
</script>

<style scoped lang="scss">
@import "src/main.scss";

.conversation-handled-banner {
  background-color: $color-semantic-success-lightest;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  min-width: 0;
  max-width: 100%;
}

.conversation-handled-banner__icon {
  flex-shrink: 0;
}

.handled-by-text {
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include font-body-14-medium;
  color: $color-semantic-success-dark;
}

.handled-by-time {
  margin-left: 4px;

  @include font-caption-normal;
  color: $color-grey-tinted-500;
}

.handled-by-infotag {
  margin-left: 12px;
}
</style>
