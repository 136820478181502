<template>
  <div style="height: 100%">
    <ConversationMessageCompose
      can-chat
      bulk
      @interface="emit('interface', $event)"
      @send="emit('send', $event)"
    />
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
import ConversationMessageCompose from "@/views/conversation-detail/components/conversation-message-compose.vue";

const emit = defineEmits(["interface", "send"]);
</script>
