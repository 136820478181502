import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import axiosRetry from 'axios-retry';

const url = process.env.VUE_APP_API_URL;

let apiOptions = {
  baseURL: url,
  headers: {
    'OG-App-Name': Capacitor.isNativePlatform() ? 'chat-native' : 'chat',
  },
};

const api = axios.create(apiOptions);

// Make it possible for requests to be retried on network errors and 5xx errors
// Is disabled per default (retries: 0), but can be enabled by setting retries when doing the request
axiosRetry(api, { retries: 0, retryCondition: (error) => {
    const errorName = error.name?.toLowerCase() || '';
    const errorMessage = error.message?.toLowerCase() || '';
    const isNetworkError = errorName.includes('network') || errorMessage.includes('network');
    const isServerError = error.response?.status > 499 && error.response?.status < 600;

    return isNetworkError || isServerError;
}, retryDelay: () => 1000 });

export { url, axios };

export default api;
