import echo from './echo';

// Global events go here
export default function sockets(store) {
  const channels = [];
  let hasConnected = false;

  const listenForNewMessages = (uid) => {
    echo
      .private(`conversations.${uid}`)
      .listen('update', (e) => {
        // mark latest_log as read if it has been written by the current user
        if (
          e.conversation?.latest_log?.user_id === store.getters['users/me']?.id
        ) {
          e.conversation.latest_log.read = true;
        }

        store.dispatch('conversations/updateLatestLog', {
          conversationId: e.conversation.id,
          log: e.conversation.latest_log,
        });
        store.commit('conversationLogs/mergeLogs', {
          id: e.conversation.id,
          logs: [e.conversation.latest_log],
          action: 'append',
        });
      })
      .listen('unread', ({ unread }) => {
        store.commit('conversations/setUnreadCount', unread);
      }).listen('handled', (e) => {
        store.commit('conversations/updateHandled', e);
      });

    channels.push(`conversations.${uid}`);
  };

  const leaveAllChannels = () => {
    while (channels.length) {
      echo.leave(channels.shift());
    }
  };

  store.subscribe((mutation) => {
    if (mutation.type === 'users/set_me' && !hasConnected) {
      listenForNewMessages(mutation.payload.id);
      hasConnected = true;
    }

    if (mutation.type === 'auth/clearToken') {
      leaveAllChannels();
    }
  });
}
