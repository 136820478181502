<template>
  <StatusTag :color="convertedStatus">{{ statusDescription }}</StatusTag>
</template>

<script>
import { StatusTag } from "@officeguru/components-vue3";

export default {
  name: "StatusBadge",
  components: {
    StatusTag,
  },
  props: {
    status: { required: true },
  },
  computed: {
    convertedStatus() {
      switch (this.status) {
        case "accepted":
          return "green";
        default:
          return "blue";
      }
    },
    statusDescription() {
      switch (this.status) {
        case "accepted":
          return this.$t("ogchat.contracts.active");
        case "open":
          return this.$t("ogchat.contracts.offer_sent");
        default:
          return this.$t("ogchat.contracts.none");
      }
    },
  },
};
</script>
