import api from '../plugins/api';

function getEndpoint(id, isSupport = false, isPublic = false) {
  return `${isPublic ? 'public/' : ''}conversations/${
    isSupport ? 'support/' : ''
  }${id}/logs`;
}

function getInitialState() {
  return {
    logs: {
      // 'conversation.id': []
    },
    logsMeta: {
      // 'conversation.id': {},
    },
    logsLoading: {
      // 'conversation.id': Boolean,
    },
  }
}

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {
    logs(state) {
      return ({ id }) => {
        return state.logs[id] || [];
      };
    },
    hasLogsMeta(state) {
      return ({ id }) => {
        return !!state.logsMeta[id];
      };
    },
    canGetMoreLogs(state) {
      return ({ id }) => {
        return !!(
          state.logsMeta[id] &&
          state.logsMeta[id].last_page > state.logsMeta[id].current_page
        );
      };
    },
    isLoading(state) {
      return ({ id }) => {
        return !!state.logsLoading[id];
      };
    },
  },
  mutations: {
    mergeLogs(state, { id, logs = [], action = 'prepend' }) {
      const existingLogs = state.logs[id] || [];
      const existingLogIds = existingLogs.map((log) => log.id);
      const newLogs = logs.filter((log) => !existingLogIds.includes(log.id));

      if (!newLogs.length) return;

      const mergedLogs =
        action === 'prepend'
          ? [...newLogs, ...existingLogs]
          : [...existingLogs, ...newLogs];

      state.logs = { ...state.logs, [id]: [...mergedLogs] };
    },
    overrideLogs(state, { id, logs = []}) {
      state.logs = { ...state.logs, [id]: [...logs] }
    },
    logsMeta(state, { id, meta }) {
      state.logsMeta = { ...state.logsMeta, [id]: meta };
    },
    logsLoading(state, { id, isLoading }) {
      state.logsLoading = { ...state.logsLoading, [id]: isLoading };
    },
    markAllLogsRead(state, { id }) {
      if (!Array.isArray(state.logs[id])) return;

      state.logs[id].forEach((log) => {
        // eslint-disable-next-line no-param-reassign
        log.read = true;
        return log;
      });
    },
    reset(state) {
      // eslint-disable-next-line no-unused-vars
      Object.keys(getInitialState()).map(key => {
        state[key] = getInitialState()[key];
      });
    }
  },
  actions: {
    async getLogs(
      { commit, state },
      { id, isSupport = false, isPublic = false, resetPage = false }
    ) {
      if (!id) return;

      commit('logsLoading', { id, isLoading: true });

      let page = 1;
      if (!resetPage && typeof state.logsMeta[id]?.current_page === 'number') {
        page = state.logsMeta[id].current_page + 1;
      }

      try {
        const response = await api.get(getEndpoint(id, isSupport, isPublic), {
          params: {
            page: page,
          },
        });

        commit(resetPage ? 'overrideLogs' : 'mergeLogs', {
          id,
          logs: response.data.data.reverse(),
        });
        commit('logsMeta', { id, meta: response.data.meta });
      } finally {
        commit('logsLoading', { id, isLoading: false });
      }
    },
    async addLog(
      { commit },
      { id, payload, isSupport = false, isPublic = false, retryAmount = 0 }
    ) {
      const response = await api.post(getEndpoint(id, isSupport, isPublic), {
        message: payload.text,
        files: payload.files,
      }, {
          'axios-retry': {
            retries: retryAmount,
          }
      });

      const log = response.data.data;
      commit('mergeLogs', { id, logs: [log], action: 'append' });
    },
  },
};
