function buildAttachmentUrl(attachment) {
   return process.env.VUE_APP_ATTACHMENT_URL + attachment;
}

function buildImageTinyUrl(attachment) {
    const tiny = attachment.replace('/', '/tiny_');
    return process.env.VUE_APP_ATTACHMENT_URL + tiny;
}

function buildImageLargeUrl(attachment) {
    const tiny = attachment.replace('/', '/large_');
    return process.env.VUE_APP_ATTACHMENT_URL + tiny;
}

export {
    buildAttachmentUrl,
    buildImageTinyUrl,
    buildImageLargeUrl,
}