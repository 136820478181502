<template>
  <ion-app>
    <ion-router-outlet />
    <Toaster :bugsnag-client="bugsnag" class="toaster" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { Network } from "@capacitor/network";
import { defineComponent } from "vue";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { Toaster } from "@officeguru/components-vue3";
import { bugsnag } from "@/libs/bugsnag";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    Toaster,
  },
  setup() {
    return { bugsnag };
  },
  created() {
    this.clearNotifications();
    document.addEventListener("resume", this.onResume);
    Network.addListener("networkStatusChange", this.updateNetworkState);
    localStorage.removeItem("reloadChatList");
    this.updateNetworkState(Network.getStatus());
  },
  methods: {
    async onResume() {
      this.clearNotifications();

      // The GENERIC_RESUME_BLACKLIST is used to prevent certain routes from using the default resume behavior.
      // The strings in the array should match the this.$route.name of the routes, e.g. 'ChatList' or 'ChatDetail'.
      // On these routes, you can then introduce your own custom resume behavior with in event listener like:
      // document.addEventListener('resume', this.onResume);
      // This is useful, when you for example would like to reload the current route when the app is resumed,
      // instead of redirecting to the ChatList.
      // This also fixes certain issues, such as the android image selection triggering a "resume" event, when returning to the app
      // see: https://officeguru.atlassian.net/browse/OG-3621
      const GENERIC_RESUME_BLACKLIST = ["ChatDetail", "Bulk"];
      if (GENERIC_RESUME_BLACKLIST.includes(this.$route.name)) {
        localStorage.setItem("reloadChatList", "true");
      } else {
        await this.$router.push({ name: "Resume" });
      }
    },
    async clearNotifications() {
      // remove all notifications (also the badge count)
      if (Capacitor.isPluginAvailable("PushNotifications")) {
        await PushNotifications.removeAllDeliveredNotifications();
      }
    },
    updateNetworkState(status) {
      this.$store.commit("settings/updateConnectionStatus", status);
    },
  },
});
</script>

<style>
.toaster {
  top: env(safe-area-inset-top)!important;
}
</style>
