import api from '../plugins/api';
import { bugsnag } from '../../libs/bugsnag';

export default {
  namespaced: true,
  state: {
    access_token: localStorage.getItem('access_token'),
  },
  getters: {
    isAuthenticated(state) {
      return !!state.access_token;
    },
  },
  mutations: {
    updateToken(state, token) {
      localStorage.setItem('access_token', token);
      state.access_token = token;
    },
    clearToken(state) {
      localStorage.removeItem('access_token');
      state.access_token = null;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      return api.post('/login', credentials).then(({ data }) => {
        commit('updateToken', data.data.token);

        // let the API know that we have a push token
        try {
          if (localStorage.getItem('push_token')) {
            api.post('account/token', {
              fcm_token: localStorage.getItem('push_token'),
            });
          }
        } catch(e) {
          console.log(e);
          bugsnag.notify(e);
        }
      });
    },
    logout({ commit }) {
      // start the logout w. a valid token
      const post = api.post('/logout');
      // remove token while the logout request is running
      commit('clearToken');
      return post;
    },
  },
};
