<template>
  <Teleport to="body">
    <IonBackdrop v-if="showing" class="backdrop"/>
    <div v-if="showing" class="overlay" @click="close">
      <div class="overlay-card">
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>

import { IonBackdrop } from "@ionic/vue";

export default {
  props: {
    showing: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  components: {
    IonBackdrop,
  },
  methods: {
      close() {
        this.$emit('close');
      }
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  animation: fadeIn 0.2s forwards linear;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  animation: fadeIn 0.2s forwards linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay-card {
  position: absolute;
  bottom: calc(16px + env(safe-area-inset-bottom));
  left: 16px;
  z-index: 10001;
}
</style>