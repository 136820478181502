<template>
  <ion-page data-cy="view-conversation-list">
    <ion-header :translucent="true">
      <ion-toolbar>
        <NavigationHeader :title="$t('ogchat.messages')">
          <template #left>
            <router-link to="/profile" class="profile-icon-link">
              <Icon name="user-circle" :size="22" />
            </router-link>
          </template>
          <template #right>
            <router-link v-if="isPartner" to="/bulk">
              <Icon
                name="pencil-square-bold"
                :size="22"
                class="bulk-icon"
                @click="showBulkModal = true"
              />
            </router-link>

            <ConversationListFilters
              v-if="filtersEnabled"
              @filters-updated="updateFilters"
              @filters-cleared="clearFilters"
              @interface="filterInterface = $event"
              :is-active="hasActiveFilters"
              :values="queryModels"
            />
          </template>
        </NavigationHeader>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" ref="content">
      <LoadingAnimation v-if="conversationsLoading" />
      <div v-else>
        <ConversationListCard
          v-for="conversation in conversations"
          :key="conversation.id"
          :conversation="conversation"
          @click="detail(conversation)"
        />
        <div class="load-more-button-wrapper" v-if="canGetMore">
          <ButtonV2
            class="load-more-button"
            type="secondary"
            :disabled="conversationsLoading"
            @click="onLoadMoreConversations"
            >{{ $t("shared.conversations.load_more_conversations") }}</ButtonV2
          >
        </div>
      </div>
      <div v-if="!conversationsLoading && conversations.length < 1">
        <ConversationListEmpty />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/vue";
import { defineComponent } from "vue";
import ConversationListCard from "../components/conversation-list-card.vue";
import NavigationHeader from "@/components/NavigationHeader.vue";
import ButtonV2 from "@officeguru/components-vue3/src/components/ButtonV2.vue";
import ConversationListEmpty from "../components/conversation-list-empty.vue";
import { sortBy } from "lodash";
import { QueryManager } from "@officeguru/webapp-shared/src/helpers/request/query-manager.js";
import { QueryContactPersons } from "@officeguru/webapp-shared/src/helpers/request/queries/query-contact-persons.js";
import { QueryIsHandled } from "@officeguru/webapp-shared/src/helpers/request/queries/query-is-handled.js";
import { QueryServices } from "@officeguru/webapp-shared/src/helpers/request/queries/query-services.js";
import { QueryCustomers } from "@officeguru/webapp-shared/src/helpers/request/queries/query-customers.js";
import { RequestManager } from "@officeguru/webapp-shared/src/helpers/request/request-manager.js";
import api from "@/store/plugins/api.js";
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import LoadingAnimation from "@/components/loading/LoadingAnimation.vue";
import ConversationListFilters from "@/views/conversation-list/components/conversation-list-filters.vue";

export default defineComponent({
  name: "ChatList",
  components: {
    ConversationListFilters,
    LoadingAnimation,
    ConversationListEmpty,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    ConversationListCard,
    NavigationHeader,
    ButtonV2,
    Icon,
  },

  data() {
    return {
      queryManager: new QueryManager(),
      requestManager: new RequestManager(api),
      queryModels: {
        contactPersons: new QueryContactPersons(),
        isHandled: new QueryIsHandled(),
        services: new QueryServices(),
        customers: new QueryCustomers(),
      },
      filterInterface: null,
      showBulkModal: false,
    };
  },
  created() {
    this.queryManager.addQueries(Object.values(this.queryModels));
  },
  computed: {
    isPartner() {
      return this.me.type === "partner";
    },
    filtersEnabled() {
      if (!this.$store.getters["split/ready"]) return false;
      return (
        this.me?.type === "partner" &&
        this.$store.getters["split/canAccess"]("ogchat-chat-list-filters")
      );
    },
    canGetMore() {
      return this.$store.getters["conversations/canGetMore"];
    },
    conversations() {
      const conversations = this.$store.getters["conversations/list"];
      const support = conversations.find((c) => c.is_support);
      const withoutSupport = conversations.filter((c) => !c.is_support);
      const unreadWithoutSupport = withoutSupport.filter(
        (c) => c.latest_log?.read
      );
      const readWithoutSupport = withoutSupport.filter(
        (c) => !c.latest_log?.read
      );

      // support first, then unreads sorted by `created_at`, then reads sorted by `created_at`
      return [
        ...(support ? [support] : []),
        ...sortBy(readWithoutSupport, ["latest_log.created_at"]).reverse(),
        ...sortBy(unreadWithoutSupport, ["latest_log.created_at"]).reverse(),
      ];
    },
    conversationsLoading() {
      if (!this.$store.getters["conversations/listLoadedInitially"])
        return true;
      // if we have meta, we already loaded the first page, therefore this loading means
      // we load more which shouldn't show the OG loading animation
      if (this.$store.getters["conversations/listMeta"]) return false;

      return this.$store.getters["conversations/listLoading"];
    },
    conversationsWithLogs() {
      if (!Array.isArray(this.conversations)) return null;

      return this.conversations.filter((c) => c.latest_log);
    },
    me() {
      return this.$store.getters["users/me"];
    },
    hasConversations() {
      return this.$store.getters["conversations/hasConversations"];
    },
    hasActiveFilters() {
      return this.queryManager.hasActiveQueries();
    },
  },
  ionViewWillEnter() {
    this.queryManager.restorePersistedQueryValuesFromStorage({
      userType: this.me.type,
    });

    if (localStorage.getItem("clearFilters")) {
      localStorage.removeItem("clearFilters");
      this.clearFilters();
      this.filterInterface?.reload();
    }

    // Wherever the user is when resuming (even when h/s/i is on THIS view),
    // we will send h/s/i to this view (see App.vue) and it'll make sure that
    // all the data is resetted / refetched so we have a proper state
    if (this.$route.params.isResume || localStorage.getItem("reloadChatList")) {
      localStorage.removeItem("reloadChatList");
      return this.onResume();
    }

    // // only fetch if we don't have more than one (which we can have by starting at the detail with a deeplink)
    // // - updates to the `latest_log` come via the websocket and when we resume we fetch fresh via the resume handler.
    // // If we'd fetch on every view enter we'd load more and more pages (or we'd have to load
    // // all the pages we already loaded again) this might lead to a problem where we have a latest_log that isn't
    // // the latest log, I don't know yet how we'd solve it w/o losing the already loaded conversation pages
    // if (this.hasConversations && this.$store.getters['conversations/list'].length > 1) return;
    this.fetchConversations(true);
  },
  methods: {
    async goToTop(duration = 0) {
      if (!(this.$refs.content && this.$refs.content.$el)) return;

      await this.$nextTick();

      this.$refs.content.$el.scrollToTop(duration);
    },
    detail({ id, is_support }) {
      this.$router.push({
        name: "ChatDetail",
        params: { id: is_support ? "support" : id },
      });
    },
    onLoadMoreConversations() {
      this.fetchConversations();
    },
    fetchConversations(resetPage = false) {
      this.queryManager.persistQueryValues(this.$router, {
        strategy: "storage",
        userType: this.me.type,
      });

      return this.$store.dispatch("conversations/get_list", {
        requestManager: this.requestManager,
        queryManager: this.queryManager,
        resetPage,
      });
    },
    async onResume() {
      // drop all logs so when we enter a conversation detail at some point we request the logs for page 1 again
      // and get all the most recent logs
      this.$store.commit("conversationLogs/reset");

      // refresh the conversation list in case the `latest_log` on one of them changed
      await this.fetchConversations(true);
      this.goToTop(300);

      if (
        global.redirectConfiguration &&
        typeof global.redirectConfiguration === "object"
      ) {
        // copy
        const conf = { ...global.redirectConfiguration };
        // reset
        global.redirectConfiguration = undefined;
        // redirect
        this.$router.push(conf);
      }
    },
    updateFilters({ contacts, handled, services, customers }) {
      this.queryModels.contactPersons.contactPersons = contacts;
      this.queryModels.isHandled.isHandled = handled;
      this.queryModels.services.services = services;
      this.queryModels.customers.customers = customers;

      this.fetchConversations(true);
    },
    clearFilters() {
      this.queryModels.contactPersons.reset();
      this.queryModels.isHandled.reset();
      this.queryModels.services.reset();
      this.queryModels.customers.reset();

      this.fetchConversations(true);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./src/main.scss";

.header {
  display: flex;
  justify-content: center;
  @include font-body-16-semi-bold;
  color: $color-grey-tinted-900;
}

.loading {
  @include blur-pulse-loading;
}

.load-more-button-wrapper {
  display: flex;
  margin: 8px 0 32px 0;
}

.load-more-button {
  margin: 0 auto;
}

.profile-icon-link {
  color: $color-ui-interactive-grey-dark;
  padding: 15px 20px 15px 9px;
  text-decoration: none;
  line-height: 0;
}

.bulk-icon {
  color: $color-grey-tinted-700;
  margin-right: 24px;
}
</style>
