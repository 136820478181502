<template>
  <div>
    <!-- Unhandled filter -->
    <div v-if="conversationHandlingEnabled" class="filter-option">
      <div class="filter-option-description">{{ $t('ogchat.show_only_unhandled_conversations') }}</div>
      <div class="filter-option-action">
        <SwitchToggle :modelValue="handled" class="handled-toggle" @update:modelValue="$emit('update-handled', $event)" />
      </div>
    </div>
    <!-- Filter pages -->
    <div v-for="filter in filterPages" :key="filter.key" class="filter-option" @click="show(filter.key)">
      <div class="filter-option-description">{{ filter.description }}</div>
      <div class="filter-option-action">
        <span class="filter-option-action-label">{{ filter.label }}</span>
        <Icon name="arrow-right" class="filter-option-action-icon" :size="22" />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@officeguru/components-vue3/src/components/Icon.vue';
import SwitchToggle from '@officeguru/components-vue3/src/components/SwitchToggle.vue';

export default {
  components: {
    Icon,
    SwitchToggle,
  },
  props: {
    handled: {
      type: Boolean,
      required: true,
    },
    contactLabel: {
      type: String,
      default: '',
    },
    serviceLabel: {
      type: String,
      default: '',
    },
    customerLabel: {
      type: String,
      default: '',
    }
  },
  computed: {
    conversationHandlingEnabled() {
      return this.$store.getters['split/canAccess'](
          'ogchat-handle-conversations'
      );
    },
    filterPages() {
      return [{
        label: this.customerLabel,
        key: 'customers',
        description: this.$t('ogchat.customers'),
      },{
        label: this.serviceLabel,
        key: 'services',
        description: this.$t('ogchat.services'),
      },{
        label: this.contactLabel,
        key: 'contacts',
        description: this.$t('ogchat.contact_persons'),
      }]
    }
  },
  methods: {
    show(filter) {
      this.$emit('show-filter', filter);
    },
  }
}
</script>

<style scoped lang="scss">
@import './src/main.scss';

.filter-option {
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  gap: 16px;

  &-description {
    @include font-body-14-semi-bold;
    color: $color-grey-tinted-900;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey-tinted-200;
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex: 1;
    min-width: 0;
  }

  &-action-label {
    @include font-body-14-normal;
    color: $color-grey-tinted-500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-action-icon {
    min-width: 22px;
  }
}

.handled-toggle::before {
  background: $color-core-cta-brand !important;
}
</style>