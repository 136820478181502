import api from "@/store/plugins/api";

function bulkMessage({ conversationIds, message, files }) {
  return api.post("partner/conversations/bulk", {
    conversation_ids: conversationIds,
    message,
    files,
  });
}

export { bulkMessage };
