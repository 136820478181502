<template>
  <div class="bulk-recipient-header-toggle" @click.exact="props.config.toggle">
    <InputCheckbox
      :modelValue="isChecked"
      @update:modelValue="props.config.toggle"
      class="recipient-checkbox"
    />
    <p style="margin: 0">{{ $t("ogchat.bulk.mark_all") }}</p>
  </div>
</template>

<script setup>
import { InputCheckbox } from "@officeguru/components-vue3";
import { defineProps, computed } from "vue";

const isChecked = computed(() => props.config.checked);

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
});
</script>

<style scoped lang="scss">
@import "@/main.scss";

.bulk-recipient-header-toggle {
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  width: 100%;
}

:deep(.checkmark-container) {
  height: 24px !important;
  width: 24px !important;
}

:deep(.input-checkbox) {
  height: 24px !important;
}

:deep(.input-checkbox__display::after) {
  height: 24px !important;
  width: 24px;
}
</style>
