import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    contacts: null,
    customer: null,
    meId: null,
    users: [],
  },
  getters: {
    customer(state) {
      return state.customer;
    },
    contacts(state) {
      return state.contacts;
    },
    me: (state) => {
      const user = state.users.find((u) => u.id === state.meId);
      return user || null;
    },
  },
  mutations: {
    set_me(state, user) {
      state.meId = user.id;
      if (user.customer) {
        state.customer = user.customer;
      }
    },
    set_customer(state, customer) {
      state.customer = customer;
    },
    set_single(state, user) {
      const oldItem = state.users.find((u) => u.id === user.id);
      if (oldItem) {
        // Migrated from Vue.set
        state.users[state.users.findIndex((u) => u.id === user.id)] = { ...oldItem, ...user };
      } else {
        state.users.push(user);
      }
    },
    set_contacts(state, contacts) {
      state.contacts = contacts.filter(
        (c, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(c)) === i
      );
    },
  },
  actions: {
    get_me({ commit, dispatch }) {
      return api
        .get('/me', {
          params: {
            with: ['customer.contact'],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);
          commit('set_me', data.data);
          dispatch('settings/setup', null, { root: true });
        });
    },
    get_contacts({ commit }) {
      return api
        .get(`/contacts`, {
          params: {
            with: ['media'],
          },
        })
        .then(({ data }) => {
          commit('set_contacts', data.data);
        });
    },
    async patch_lang({ dispatch }, { lang }) {
      await api.patch(`/me`, { lang });
      return dispatch('get_me');
    }
  },
};
