<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <LoadingAnimation />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import LoadingAnimation from '@/components/loading/LoadingAnimation.vue';

/**
 * This view is redirected to whenever the app is resumed (e.g. brought back from being minimized).
 * It has been introduced because redirecting to the `ChatList` wasn't enought when the user was already
 * at the ChatList (the lifecycle events of the ChatList were not triggered in that case).
 */
export default defineComponent({
  name: 'ResumeHandler',
  components: { LoadingAnimation, IonPage, IonContent },
  ionViewDidEnter() {
    // use replace because we don't want the resume page in the history
    setTimeout(() => {
      this.$router.replace({ name: 'ChatList', params: { isResume: true } });
    }, 300)
  }
});
</script>