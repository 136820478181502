import {QueryAbstract} from './query-abstract';

class QueryPage extends QueryAbstract {
  reset() {
    this._page = null;
  }

  serialize() {
    return {
      page: this._page,
    }
  }

  unserialize(filter) {
    const { page } = filter;

    this.page = page;
  }

  set page(value) {
    const number = parseInt(value);

    if (typeof number !== 'number' || isNaN(number)) return this.reset();

    this._page = number;
  }

  get page() {
    return this._page;
  }
}

export { QueryPage }