import { createApp } from 'vue'
import { router } from './router';
import { store } from './store';
import { i18n } from './i18n';
import { bugsnag } from './libs/bugsnag';
import { IonicVue } from '@ionic/vue';
import './libs/ionic.css.js';
import App from './App.vue'
import './push.js';
import './helpers/env-check.js';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';

// Lock orientation to portrait as we currently don't support landscape
ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT).catch((e) => {
  if (e.message.includes('is not available on this device')) return;
  throw e;
})

const app = createApp(App)
    .use(store)
    .use(IonicVue)
    .use(router)
    .use(i18n)
    .use(bugsnag.getPlugin('vue'));

router.isReady().then(() => {
  app.mount('#app');
});