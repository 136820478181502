import { createStore } from 'vuex';

import apiplugin from './plugins/apiplugin';
import sockets from './plugins/sockets';

import auth from './modules/auth';
import users from './modules/users';
import conversations from './modules/conversations';
import conversationLogs from './modules/conversation-logs';
import settings from './modules/settings';
import split from './modules/split';

const store = createStore({
  modules: {
    auth,
    conversations,
    conversationLogs,
    users,
    settings,
    split,
  },
  plugins: [apiplugin, sockets],
})

export {
  store
};
