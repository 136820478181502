<template>
  <label
    data-cy="cmp-switch-toggle"
    :class="rootClass"
  >
    <input
      type="checkbox"
      :checked="modelValue"
      @change="handleChange"
    >
  </label>
</template>

<script>
export default {
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: { type: [Boolean, Number], required: true },
  },
  emits: ['update:modelValue'],
  computed: {
    rootClass() {
      return {
        switchtoggle: true,
        'switchtoggle--on': this.modelValue,
      };
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.switchtoggle {
  border: 1px solid rgba(#000, .1);
  height: 32px;
  border-radius: 16px;
  width: 52px;
  display: inline-flex;
  position: relative;

  input {
    position: absolute;
    left: -9999px;
  }

  &::before {
    content: "";
    background: $color-brand-600;
    height: 32px;
    border-radius: 16px;
    width: 52px;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    opacity: 0;
    transition: opacity .15s ease-out;
  }

  &::after {
    content: "";
    display: block;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 15px;
    transition: transform .15s ease-out, box-shadow .15s ease-out;
    position: relative;
    margin: 1px;
    z-index: 2;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
      0 2px 2px 0 rgba(0,0,0,.14),
      0 1px 5px 0 rgba(0,0,0,.12);
  }

  &--on {

    &::before {
      opacity: 1;
    }

    &::after {
      transform: translateX(20px);
      box-shadow: none;
    }
  }
}
</style>
