<template>
  <div class="conversation-message-area">
    <ButtonV2 v-if="canGetMoreLogs"
              @click="$emit('loadMore')"
              :disabled="logsLoading"
              icon-left="refresh"
              class="load-more-button"
              type="secondary"
              small>{{ $t('shared.conversations.load_more_messages') }}</ButtonV2>
    <template v-for="(messages, date) in messageBlocks" :key="date">
      <div class="date">
        <span class="date__inner">{{ formatDate(date) }}</span>
      </div>
      <ConversationMessageAreaMessageMessage v-for="message in messages" :key="message.id" :message="message" />
    </template>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import ConversationMessageAreaMessageMessage from './conversation-message-area-message.vue';
import {dayLongDayNumericMonthLong} from '@/helpers/date-format.js';
import ButtonV2 from '@officeguru/components-vue3/src/components/ButtonV2.vue';

const IMAGE_EXTS = /\.(jpg|jpeg|png|gif|tiff|bmp)$/;

export default defineComponent({
  name: 'ConversationMessageArea',
  components: {
    ConversationMessageAreaMessageMessage,
    ButtonV2,
  },
  props: {
    conversation: {},
    logs: {},
    logsLoading: {},
    canGetMoreLogs: {},
  },
  computed: {
    me() {
      return this.$store.getters['users/me'];
    },
    myId() {
      return this.me.id;
    },
    // TODO: Copy from "chat-conversatoin-message-area.vue" - extract to shared function!
    messages() {
      const messages = this.logs.filter((log) => log.type === 'message');
      let lastMessageAuthor = null;
      let lastMessageDate = null;
      return messages.map((message) => {
        const content = JSON.parse(message.content);
        const files = content.files || [];
        let hideByline = false;
        const messageUser = message.user
            ? { ...message.user }
            : { id: '', type: null, name: this.$t('shared.conversations.deleted_user') };

        if (
            lastMessageDate
            && lastMessageAuthor === messageUser.id
            && new Date(message.created_at) - lastMessageDate < 1000 * 60 * 5
        ) {
          hideByline = true;
        }
        lastMessageDate = new Date(message.created_at);
        lastMessageAuthor = messageUser.id;

        return {
          ...message,
          attachments: files.filter((file) => file && !file.match(IMAGE_EXTS)),
          images: files.filter((file) => file && file.match(IMAGE_EXTS)),
          message: { text: content.message, date: message.created_at },
          author: messageUser,
          type: messageUser.id === this.myId ? 'mine' : 'theirs',
          fromOfficeguru: messageUser.type === 'backoffice',
          hideByline,
          linkTo: null,
          linkText: null,
        };
      });
    },
    // TODO: Copy from "chat-conversatoin-message-area.vue" - extract to shared function!
    messageBlocks() {
      return this.messages.reduce((blocks, message) => {
        const date = message.created_at.split('T')[0];
        // eslint-disable-next-line no-param-reassign
        blocks[date] = blocks[date] ? [...blocks[date], message] : [message];
        return blocks;
      }, {});
    },
  },
  methods: {
    formatDate(dateString) {
      return dayLongDayNumericMonthLong(dateString, this.me?.lang);
    },
  }
});
</script>

<style lang="scss" scoped>
@import '@/main.scss';

.conversation-message-area {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.date {
  @include font-caption-normal;
  color: $color-grey-tinted-500;
  display: flex;
  flex-direction: row;
  margin: 8px 0;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $color-grey-tinted-100;
    margin: auto;
  }

  &__inner {
    line-height: 0;
    border: 1px solid $color-grey-tinted-100;
    padding: 15px 12px 14px 12px;
    border-radius: 37px;
    text-transform: capitalize;
  }
}

.load-more-button {
  margin: 16px auto;
}
</style>