import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const bugsnag = Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV,
    // eslint-disable-next-line global-require
    appVersion: require('../../package.json').version,
})

export {
    bugsnag,
}