import {QueryAbstract} from './query-abstract';

class QuerySearch extends QueryAbstract {
  reset() {
    this._search = null;
  }

  serialize() {
    return {
      search: this._search || null,
    }
  }

  unserialize(filter) {
    const { search } = filter;

    this._search = search;
  }

  set search(value) {
    if (!(typeof value === 'string' && value)) return this.reset();

    this._search = value;
  }

  get search() {
    return this._search;
  }
}

export { QuerySearch }