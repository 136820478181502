const TASK_STATUS_MAP = {
    COLLECTING: 'collecting',
    INTERESTED: 'interested',
    OPEN: 'open',
    ACCEPTED: 'accepted',
    ENDED: 'ended',
    REJECTED: 'rejected',
    DECLINED: 'declined',
    DISPUTED: 'disputed',
    ARCHIVED: 'archived',
}

const CUSTOMER_TASK_STATUS_MAP = {
    ACCEPTED: 'accepted',
  ARCHIVED: 'archived',
  COLLECTING: 'collecting',
  DISPUTED: 'disputed',
}

const TASK_STATUS = Object.values(TASK_STATUS_MAP);

const TASK_STATUS_TRANSLATIONS = {
    'all': 'shared.all',
    [TASK_STATUS_MAP.COLLECTING]: 'shared.customer_request',
    [TASK_STATUS_MAP.INTERESTED]: 'shared.in_dialogue',
    [TASK_STATUS_MAP.OPEN]: 'shared.offer_sent',
    [TASK_STATUS_MAP.ACCEPTED]: 'shared.accepted',
    [TASK_STATUS_MAP.ENDED]: 'shared.completed',
    [TASK_STATUS_MAP.REJECTED]: 'shared.rejected',
    [TASK_STATUS_MAP.DECLINED]: 'shared.declined',
    [TASK_STATUS_MAP.DISPUTED]: 'shared.disputed',
    [TASK_STATUS_MAP.ARCHIVED]: 'shared.archived',
}

export {
    TASK_STATUS,
    TASK_STATUS_MAP,
    TASK_STATUS_TRANSLATIONS,
}
