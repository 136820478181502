import { SplitFactory } from '@splitsoftware/splitio';

let client = null;

export default {
    namespaced: true,
    state: {
        ready: false,
    },
    getters: {
        ready(state) {
            return state.ready;
        },
        canAccess: (_1, _2, _3, rootGetters) => (flag, value = 'on') => {
            const user = rootGetters['users/me'];
            const userType = user.type;
            const attributes = {
                name: user.name,
                email: user.email,
                type: userType,
                customer_id: userType === 'customer' ? user.id : null,
                customer: userType === 'customer' ? user.name : null,
                partner_id: userType === 'partner' ? user.id : null,
                partner: userType === 'partner' ? user.name : null,
                lead: false,
            };

            return client.getTreatment(flag, attributes) === value;
        },
    },
    mutations: {
        ready(state, ready) {
            state.ready = ready;
        },
    },
    actions: {
        setup({ getters, commit }, userId) {
            return new Promise((resolve) => {
                if (getters.ready) {
                    resolve();
                } else {
                    client = SplitFactory({
                        core: {
                            authorizationKey: process.env.VUE_APP_SPLITIO_API_KEY,
                            key: userId,
                        },
                    }).client();

                    client.on(client.Event.SDK_READY, () => {
                        commit('ready', true);

                        resolve();
                    });
                }
            });
        },
    },
};
