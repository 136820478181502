import api from './api';
import echo from './echo';
import { bugsnag } from '@/libs/bugsnag';

export default function apiplugin(store) {
  if (store.state.auth.access_token) {
    api.defaults.headers.common.authorization = `Bearer ${store.state.auth.access_token}`;
    console.log(echo.connector.pusher.config)
    echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${store.state.auth.access_token}`;
  }

  store.subscribe((mutation) => {
    if (mutation.type === 'users/set_me') {
      bugsnag.setUser(mutation.payload.id, mutation.payload.email, mutation.payload.name);
      api.defaults.params = typeof api.defaults.params === 'undefined' ? {} : api.defaults.params;
      api.defaults.params.lang = mutation.payload.lang;
    }

    if (mutation.type === 'auth/updateToken') {
      api.defaults.headers.common.authorization = `Bearer ${mutation.payload}`;
      echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${mutation.payload}`;
    } else if (mutation.type === 'auth/clearToken') {
      delete api.defaults.headers.common.authorization;
      // TODO Also clear echo token
    }
  });

  api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // ignore 401s for logout and login because we don't want to end up at the logout page in those cases
          if (new RegExp(/(\/logout|\/login)/).test(error.response.config.url)) {
            return null;
          }

          window.location.href = '/logout';
        }
        return Promise.reject(error);
      }
  );
}
