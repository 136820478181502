<template>
  <div
    class="personalized-table"
    :class="tableClasses"
    data-cy="cmp-personalized-table"
  >
    <template v-if="!showInfo || info?.showTableHeader">
      <personalized-table-header
        v-if="!hideHeader"
        :column-list="columnList"
        :column-widths="columnWidths"
        :order-map="orderMap"
        :show-skeleton="showSkeleton"
        @ordering="(value) => $emit('ordering', value)"
        @colheaderevent="$emit('colheaderevent', $event)"
      />
    </template>
    <template v-if="showInfo">
      <personalized-table-info v-bind="info" />
    </template>
    <template v-else>
      <personalized-table-body
        v-if="!showInfo"
        :show-skeleton="showSkeleton"
        :row-component="rowComponent"
        :row-options="rowOptions"
        :column-widths="columnWidths"
        :row-list="rowList"
        @colevent="$emit('colevent', $event)"
      />
    </template>
  </div>
</template>

<script>
import PersonalizedTableHeader from './PersonalizedTableHeader.vue';
import PersonalizedTableBody from './PersonalizedTableBody.vue';
import PersonalizedTableInfo from './PersonalizedTableInfo.vue';

const PersonalizedTable = {
  name: 'PersonalizedTable',
  components: {
    PersonalizedTableHeader,
    PersonalizedTableBody,
    PersonalizedTableInfo,
  },
  props: {
    hideHeader: {
      type: Boolean,
    },
    rowOptions: {
      type: Object,
      default: () => ({}),
    },
    rowComponent: {
      validator(val) {
        return typeof val === 'object' && typeof val.render;
      },
      required: true,
    },
    columnList: {
      validator(value) {
        return Array.isArray(value) && !!value.length;
      },
      default: () => [],
    },
    rowList: {
      type: Array,
      default: () => [],
    },
    orderMap: {
      validator(val) {
        return val !== null && typeof val === 'object';
      },
      default: () => ({}),
    },
    columnWidths: {
      type: Array,
      default: () => [],
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      required: false,
    },
  },
  emits: ['ordering', 'colheaderevent', 'colevent'],
  computed: {
    showInfo() {
      return this.info && !this.showSkeleton;
    },
    tableClasses() {
      return {
        'shows-info': !this.showInfo,
      };
    },
  },
};
export default PersonalizedTable;
</script>

<style scoped lang="scss">
@import "../../style/main";

.personalized-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 12px;
  overflow: visible;
  border-bottom: 0;
}

.shows-info {
  box-shadow: 0px 2px 24px 0px #05051f05;
  border: 1px solid $color-grey-200;
}
</style>
