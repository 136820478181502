import { QueryAbstract } from './query-abstract';
import { TASK_STATUS } from '../../../configuration/taskStatus';

class QueryStatus extends QueryAbstract {
  reset() {
    this._status = null;
  }

  serialize() {
    return {
      status: this._status,
    }
  }

  unserialize(filter) {
    const { status } = filter;

    this._status = status;
  }

  set status(value) {
    // null is fine for `all` tab
    if (value !== null && !TASK_STATUS.includes(value)) throw new RangeError('Wrong status value. Supported: ' + TASK_STATUS.join(', '));

    this._status = value;
  }

  get status() {
    return this._status;
  }
}

export { QueryStatus, TASK_STATUS }