import {
    PushNotifications,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

(() => {
    if (!Capacitor.isPluginAvailable('PushNotifications')) {
        console.log(`
            Push not available, but you can still test in a browser by doing:

            const push = new CustomEvent('pushNotificationActionPerformed', {
                detail:
                    {
                        "actionId": "tap",
                        "notification": {
                            "id": "0:1660907704037489%9c85ecd29c85ecd2",
                            "data": {
                                "google.delivered_priority": "normal",
                                "google.sent_time": "1660907704029",
                                "google.ttl": "2419200",
                                "google.original_priority": "normal",
                                "conversation_id": "b1RDNHlmWEFtUWd0L29xNUwwVE1wNzNOWlQ5eGkvQ2xmcExFdDd6U0RTZz0=",
                                "is_support": "false",
                                "from": "306160856002",
                                "type": "message",
                                "collapse_key": "dk.officeguru.chat"
                            }
                        }
                    }
            })
            document.dispatchEvent(push);
        `);
        document.addEventListener('pushNotificationActionPerformed', (e) => onPushNotificationActionPerformed(e.detail));
        return;
    }

    PushNotifications.addListener('registration', (token) => {
        console.log('Push registration success, token: ' + token.value);
        localStorage.setItem('push_token', token.value);
    });

    PushNotifications.addListener('registrationError', (error) => {
        console.log('Push registration error');
        alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
            console.log(JSON.stringify(notification));
        },
    );

    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        onPushNotificationActionPerformed,
    );

    // Request permission for push
    PushNotifications.requestPermissions().then(result => {
        console.log('Requested permission', JSON.stringify(result));
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            console.log('Push notifications registering');
            PushNotifications.register();
        } else {
            // Show some error
        }
    });
})()


function onPushNotificationActionPerformed(action) {
    console.log('Push action performed: ' + JSON.stringify(action));

    if (action.actionId === 'tap' &&
        typeof action.notification.data === 'object' &&
        action.notification.data.type === 'message' &&
        action.notification.data.conversation_id) {

        // this can be picked up somewhere else and handled (at the time of writing in `chat-list.vue`)
        global.redirectConfiguration = {
            name: 'ChatDetail',
            params: {
                // it's a string bcs. FCM doesn't allow booleans
                id: action.notification.data.is_support === 'true'
                    ? 'support'
                    : action.notification.data.conversation_id
            }
        };

        PushNotifications.removeAllDeliveredNotifications();
    }
}

