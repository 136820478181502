<template>
  <ion-page data-cy="view-bulk-detail" :class="{ disabled: loading }">
    <ion-header :translucent="true">
      <ion-toolbar>
        <NavigationHeader
          :title="$t('ogchat.bulk.bulk_message')"
          :sub-title="subtitle"
        >
          <template #left>
            <div @click="cancel" class="close-icon">
              <Icon name="close" :size="22" />
            </div>
          </template>
          <template #right>
            <div
              v-if="recipientInterface && step === 1"
              class="filter-icon"
              expand="block"
              :class="{ active: !!recipientInterface.activeFiltersCount }"
              @click="recipientInterface.toggleFilterModal"
              style="position: relative"
            >
              <Icon name="funnel" :size="20" />
              <div
                v-if="
                  recipientInterface.activeFiltersCount &&
                  recipientInterface.activeFiltersCount > 0
                "
                class="filter-count"
              >
                <span style="margin-top: 2px">{{
                  recipientInterface.activeFiltersCount
                }}</span>
              </div>
            </div>
          </template>
        </NavigationHeader>
      </ion-toolbar>
    </ion-header>

    <IonContent>
      <div class="content">
        <BulkDetailRecipients
          v-show="step === 1"
          @interface="recipientInterface = $event"
          :model-value="chosenRecipients"
          @update:model-value="chosenRecipients = $event"
        />

        <BulkDetailMessage
          v-show="step === 2"
          @interface="messageInterface = $event"
          @send="sendMessages"
        />
      </div>
    </IonContent>

    <IonFooter>
      <IonToolbar class="button-bar">
        <div slot="start">
          <div v-if="step === 1" @click="cancel" class="profile-icon-link">
            <ButtonV2 small type="secondary">{{
              $t("ogchat.bulk.cancel")
            }}</ButtonV2>
          </div>
          <ButtonV2 v-else @click="step = 1" small type="secondary">{{
            $t("ogchat.bulk.back")
          }}</ButtonV2>
        </div>
        <div slot="end">
          <ButtonV2 small @click="next">{{
            step === 1 ? $t("ogchat.bulk.next") : $t("ogchat.bulk.send")
          }}</ButtonV2>
        </div>
      </IonToolbar>
    </IonFooter>
  </ion-page>
</template>
<script setup>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonFooter,
} from "@ionic/vue";
import { useToaster } from "@officeguru/components-vue3/src/composables/use-toaster";
import NavigationHeader from "@/components/NavigationHeader.vue";
import { ButtonV2 } from "@officeguru/components-vue3";
import { ref, computed } from "vue";
import BulkDetailRecipients from "@/views/bulk-messaging/components/bulk-detail-recipients.vue";
import BulkDetailMessage from "@/views/bulk-messaging/components/bulk-detail-message.vue";
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import { bulkMessage } from "@/api/conversations";
import { useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { addToast } = useToaster();
const router = useRouter();
const subtitle = computed(() => {
  if (step.value === 1) {
    if (chosenRecipients.value.length) {
      return t("ogchat.bulk.count_recipients_selected", {
        count: chosenRecipients.value.length,
      });
    }
    return t("ogchat.bulk.select_recipients");
  } else {
    return t("ogchat.bulk.compose_message");
  }
});

// steps
const step = ref(1);

function next() {
  if (step.value === 1) {
    if (chosenRecipients.value.length) {
      step.value = 2;
    }
  } else {
    messageInterface.value.send();
  }
}

// recipients
const recipientInterface = ref(null);
const messageInterface = ref(null);
const chosenRecipients = ref([]);

onIonViewWillEnter(() => {
  step.value = 1;
  chosenRecipients.value = [];
});

// sending
const loading = ref(false);
function sendMessages({ payload, sentStatusCallback }) {
  if ((!payload.text && !payload.files.length) || loading.value) {
    return;
  }
  loading.value = true;
  bulkMessage({
    conversationIds: chosenRecipients.value,
    message: payload.text,
    files: payload.files,
  })
    .then(() => {
      addToast({
        text: t("ogchat.bulk.message_sent_to_count_recipients", {
          count: chosenRecipients.value.length,
        }),
        type: "success",
      });
      sentStatusCallback();
      router.push({ name: "ChatList" });
    })
    .catch(() => {
      addToast({
        text: t("ogchat.bulk.something_went_wrong"),
        type: "error",
      });
    })
    .finally(() => {
      loading.value = false;
    });
}

function cancel() {
  messageInterface.value.clear();
  router.push({ name: "ChatList" });
}
</script>

<style scoped lang="scss">
@import "@/main.scss";

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button-bar {
  padding: 0 12px;
}

.filter-icon,
.close-icon {
  color: $color-grey-700;
}

.filter-count {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-grey-900;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>
