<template>
  <div class="personalized-table-header">
    <div class="personalized-table-header__row">
      <template
        v-for="(column, index) in columnList"
        :key="column.label"
      >
        <PersonalizedTableHeaderCell
          v-if="!column.component"
          :class="{ hidden: column.hidden }"
          data-cy="cmp-table-header__column"
          :cell-content="column"
          :order-map="orderMap"
          :show-skeleton="showSkeleton || null"
          :style="{ width: columnWidth(index) }"
          @ordering="(value) => $emit('ordering', value)"
        />
        <component
          :is="column.component"
          :config="column.config"
          :show-skeleton="showSkeleton || null"
          :style="{ width: columnWidth(index) }"
          @colheaderevent="$emit('colheaderevent', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import PersonalizedTableHeaderCell from './PersonalizedTableHeaderCell.vue';

export default {
  name: 'PersonalizedTableHeader',
  components: {
    PersonalizedTableHeaderCell,
  },
  props: {
    columnList: {
      validator(value) {
        return Array.isArray(value) && !!value.length;
      },
      default: () => [],
    },
    columnWidths: {
      type: Array,
      default: () => [],
    },
    orderMap: {
      validator(val) {
        return val !== null && typeof val === 'object';
      },
      default: () => ({}),
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['ordering', 'colheaderevent'],
  methods: {
    columnWidth(index) {
      const width = `${this.columnWidths[index]}`;

      return width.includes('px') ? width : `${width}%`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

.personalized-table-header,
.personalized-table-header__row {
  display: flex;
  min-width: 100%;
}

.personalized-table-header {
  @include font-body-14-medium;
  color: $color-grey-tinted-700;
  background: $color-grey-tinted-150;
  border-bottom: 1px solid $color-grey-200;
}

.hidden {
  visibility: hidden;
}
</style>
