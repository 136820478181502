<template>
  <div style="opacity: 0" :style="tooltipLocationOverwrite" class="og-tooltip" :class="{ 'og-tooltip--open': open }" @click="hide">
    <div class="inner">
      <span class="og-tooltip-text" v-html="message" />
    </div>
    <svg class="og-tooltip-pointer" width="18" height="6" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70711 5.29289L13.2426 1.75736C14.3679 0.632141 15.894 0 17.4853 0H9H0.514719C2.10602 0 3.63214 0.632142 4.75736 1.75736L8.29289 5.29289C8.68342 5.68342 9.31658 5.68342 9.70711 5.29289Z" fill="#242545"/>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "OgTooltip",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      coordinates: null,
    }
  },
  computed: {
    tooltipLocationOverwrite() {
      if (!this.coordinates) return null;
      const bottomOffset = window.innerHeight - this.coordinates.y + 15;
      const rightOffset = window.innerWidth - this.coordinates.x - 15;
      return `position: fixed; bottom: initial; bottom:${bottomOffset}px; right: ${rightOffset}px`;
    }
  },
  created() {
    this.exposeInterface();
  },
  beforeUnmount() {
    removeEventListener('click', this.closeOnClick);
  },
  methods: {
    show(coordinates = null) {
      this.coordinates = coordinates;
      this.open = true;
      // This is to avoid the initial click that opens the tooltip, is not registered by the event listener
      // despite being registered after opening, it catches on, even when using $nextTick
      setTimeout(() => {
        addEventListener('click', this.closeOnClick);
      }, 100)
    },
    hide() {
      removeEventListener('click', this.closeOnClick);
      this.open = false;
    },
    toggle(coordinates = null) {
      if (!this.open) {
        this.show(coordinates);
      } else {
        this.hide();
      }
    },
    exposeInterface() {
      this.$emit('interface', {
        show: (coordinates = null) => this.show(coordinates),
        hide: () => this.hide(),
        toggle: (coordinates = null) => this.toggle(coordinates),
      });
    },
    closeOnClick() {
      if (this.open) {
        this.hide();
      }
    }
  }
})
</script>

<style scoped lang="scss">
@import './src/main.scss';

.og-tooltip {
  position: absolute;
  right: 0;
  bottom: 43px;
  background: $color-grey-tinted-900;
  border-radius: 8px;
  padding: 8px 12px;
  transition: opacity 0.5s;
  pointer-events: none;
  max-width: 80vw;
  margin-left: 20px;

  &--open {
    pointer-events: all;
    opacity: 1!important;
  }
}

.og-tooltip {
  position: absolute;
  right: 16px;
  bottom: 56px;
  background: $color-grey-tinted-900;
  border-radius: 8px;
  padding: 8px 12px;
}

.og-tooltip-pointer {
  position: absolute;
  bottom: -6px;
  right: 5px;
}

.og-tooltip-text {
  @include font-body-14-normal;
  color: $color-grey-tinted-0;
}
</style>
