import api from '../plugins/api';
import { bugsnag } from "@/libs/bugsnag";

// Note: All logs handling EXCEPT `latest_log` (because we need it for
// the excerpts in the conversations list) has been removed
// from this module and moved to the `conversation-logs` module.
function getInitialState() {
  return {
    conversations: [],
    listMeta: {},
    unreadCount: 0,
    loadingList: false,
    loadingSingle: {
      // id<conversation_id>: true (loading)/false (not loading)
    },
    listLoadedInitially: false,
  }
}

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {
    list(state) {
      return state.conversations;
    },
    listLoading(state) {
      return state.loadingList;
    },
    listLoadedInitially(state) {
      return state.listLoadedInitially;
    },
    listMeta(state) {
      return state.listMeta;
    },
    canGetMore(state) {
      if (!state.listMeta) return false;

      return state.listMeta.current_page < state.listMeta.last_page;
    },
    hasConversations(state) {
      return state.conversations.length > 0;
    },
    single(state) {
      return ({ id }) => {
        const conversation = state.conversations.find((c) =>
          id === 'support' ? c.is_support : c.id === id
        );
        return { ...(conversation || null) };
      };
    },
    unreadCount(state) {
      return state.unreadCount;
    },
  },
  mutations: {
    clearList(state) {
      state.conversations = [];
      state.listMeta = {};
    },
    setList(state, { conversations, startFresh }) {
      if (startFresh) {
        state.conversations = [...conversations];
        return;
      }

      const newConversations = conversations.filter((newConversation) => {
        return !state.conversations.find((oldConversation) => {
          return oldConversation.id === newConversation.id;
        });
      });

      state.conversations = [...state.conversations, ...newConversations];
    },
    setListLoadedInitially(state) {
      state.listLoadedInitially = true;
    },
    setListMeta(state, meta) {
      state.listMeta = meta;
    },
    addSingle(state, conversation) {
      const index = state.conversations.findIndex(
        (conv) => conv.id === conversation.id
      );

      // not found - just add to the end
      if (index === -1) {
        state.conversations = [...state.conversations, { ...conversation }];
        return;
      }

      // endpoint for support conversation does not return latest_log - keep it
      // if we already have it
      if (conversation.is_support) {
        // but update the handled state before we leave
        if (
          typeof state.conversations[index].handled === 'object' &&
          typeof conversation.handled === 'object'
        ) {
          state.conversations[index].handled = conversation.handled;
        }
        return;
      }

      // found - replace at exact position
      const updatedConversations = [...state.conversations];
      updatedConversations[index] = conversation;
      state.conversations = [...updatedConversations];
    },
    updateLatestLog(state, { conversationId, log }) {
      const index = state.conversations.findIndex(
        (conversation) => conversation.id === conversationId
      );

      const updatedConversations = [...state.conversations];
      updatedConversations[index] = {
        ...state.conversations[index],
        latest_log: log,
      };

      state.conversations = [...updatedConversations];
    },
    markLatestLogRead(state, conversationId) {
      const index = state.conversations.findIndex(
        (c) => c.id === conversationId
      );

      if (index === -1 || !state.conversations[index].latest_log) return;

      // mark the conversations latest_log as read
      const updatedFoundConversation = {
        ...state.conversations[index],
        latest_log: {
          ...state.conversations[index].latest_log,
          read: true,
        },
      };

      // put the conversation back in
      const updatedConversations = [...state.conversations];
      updatedConversations[index] = { ...updatedFoundConversation };

      state.conversations = [...updatedConversations];
    },
    setUnreadCount(state, unreadCount) {
      state.unreadCount = unreadCount;
    },
    updateHandled(state, conversation) {
      const index = state.conversations.findIndex(
          (conv) => conv.id === conversation.id
      );

      if (
          typeof state.conversations[index].handled === 'object' &&
          typeof conversation.handled === 'object'
      ) {
        state.conversations[index].handled = conversation.handled;
      } else {
        bugsnag.notify(new Error(`Conversation missing handled property in original or updated conversation.`), (event) => {
          event.addMetadata('additional', {
            originalConversation: state.conversations[index],
            updatedConversation: conversation
          });
        });
      }
    },
  },
  actions: {
    handle({ commit }, { conversationId }) {
      return api
          .post(`partner/conversations/${conversationId}/handled`)
          .then((res) => {
            commit('addSingle', res.data.data);
          });
    },
    unhandle({ commit }, { conversationId }) {
      return api
          .post(`partner/conversations/${conversationId}/unhandled`)
          .then((res) => {
            commit('addSingle', res.data.data);
          });
    },
    get_list(
      { commit, state, rootGetters },
      { requestManager, queryManager, resetPage } = {}
    ) {
      state.loadingList = true;

      const userType = rootGetters['users/me']?.type;

      let page = 1;
      if (!resetPage && typeof state.listMeta.current_page === 'number') {
        page = state.listMeta.current_page + 1;
      }

      const withActiveQueries = queryManager.hasActiveQueries();
      return requestManager
        .request(`${userType}/conversations`, {
          per_page: 15,
          ...queryManager.getSerializedQueries(),
          page,
        })
        .then(({ data, meta }) => {
          commit('setListMeta', { withActiveQueries, ...meta });
          commit('setList', { conversations: data, startFresh: resetPage });
          commit('setListLoadedInitially');
          state.loadingList = false;
        });
    },
    get_single({ commit, rootGetters }, { id }) {
      if (!id) return null;

      const userType = rootGetters['users/me']?.type;

      return api
        .get(
          id === 'support'
            ? '/conversations/support'
            : `/${userType}/conversations/${id}`
        )
        .then(({ data }) => {
          commit(
            'addSingle',
            Array.isArray(data.data) ? data.data[0] : data.data
          );
        });
    },
    async getUnread({ commit }) {
      const response = await api.get('conversations/unread', {});
      commit('setUnreadCount', response.data);
    },
    async read({ commit }, { id, isSupport = false, isPublic = false }) {
      if (!id) return;

      const endpoint = `${isPublic ? '/public' : ''}/conversations/${
        isSupport ? 'support/' : ''
      }${id}/read`;

      const response = await api.post(endpoint);

      commit('markLatestLogRead', isSupport ? 'support' : response.data.data.id);
      commit(
        'conversationLogs/markAllLogsRead',
        { id: response.data.data.id },
        { root: true }
      );
    },
    // eslint-disable-next-line consistent-return
    async updateLatestLog(
      { getters, state, dispatch, commit },
      { conversationId, log } = {}
    ) {
      // read https://office-guru.slack.com/archives/GAYR70ZEK/p1651641677830179?thread_ts=1651636321.605349&cid=GAYR70ZEK
      // if you want to understand the why behind the following conditions :)
      const withActiveQueries = state.listMeta.withActiveQueries || false;
      const conversation = getters.single({ id: conversationId });
      const hasConversation = !!Object.keys(conversation).length;

      if (hasConversation) {
        return commit('updateLatestLog', { conversationId, log });
      }

      if (!hasConversation && !withActiveQueries) {
        await dispatch('get_single', { id: conversationId });
        // eslint-disable-next-line consistent-return
        return;
      }

      // no conversation but active queries - ignored for now
      console.warn(
        'store/modules/conversations: Got a latest log (from the websocket) but filters were set and we did not know if the conversation would fit or not and therefore ignored it.'
      );
    },
  },
};
