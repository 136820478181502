import Echo from 'laravel-echo';
import 'pusher-js';

let headers = {};

// if (!env.isProduction()) {
//   headers = {
//     'CF-Access-Client-Id': process.env.VUE_APP_CF_ACCESS_CLIENT_ID,
//     'CF-Access-Client-Secret': process.env.VUE_APP_CF_ACCESS_CLIENT_SECRET,
//   };
// }

const connection = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  namespace: '',
  authEndpoint: `${process.env.VUE_APP_API_URL}broadcasting/auth`,
});

connection.connector.pusher.config.auth = {
  headers,
}

export default connection;
